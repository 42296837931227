@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --upsun-colors-neutral-light-white: #ffffff;

  --upsun-colors-black-900: #181e21;
  --upsun-colors-black-800: #252D31;

  --upsun-colors-violet-600: #6046FF;
  --upsun-colors-violet-900: #130E33;

  --upsun-colors-yellow-400: #E6FB66;
}

@layer base {
    body {
      @apply bg-black text-default text-sm font-sans-soft;
    }
    h1 {
      @apply font-bold
    }
    h2 {
      @apply text-15 font-bold
    }
    aside {
      @apply w-1/4 border-[1px] bg-upsun-black-900 border-t-0 border-upsun-violet-600;
    }

    aside > section {
      @apply border-b-[1px] border-upsun-violet-600 p-6 pl-4 pr-4
    }

    code {
      @apply p-0 font-mono inline-flex justify-center items-center rounded-md bg-upsun-black-800 text-xs text-upsun-yellow-400 leading-5
    }

    .TooltipContent {
      @apply p-2 bg-[#302F45] rounded-md font-sans-soft;
      box-shadow: 0px 1px 4px 0px rgba(26, 25, 43, 0.40);
    }

    .TooltipArrow {
      @apply fill-[#302F45]
    }

    .is-disabled {
      @apply select-none pointer-events-none opacity-25
    }

    .code-block > span > code {
      display: block;
      font-family: "Overpass Mono", monospace !important;
      font-size: 0.75rem !important;
      line-height: 1.25rem !important;
    }

    a {
      color: var(--upsun-colors-yellow-400);
      text-decoration: underline;
    }
  }